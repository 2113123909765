<template>
  <div class="iss-main">
    <a-row type="flex" style="height: 100%; justify-content: space-between">
      <!-- 左侧预览区域 -->
      <a-col :span="8" class="iss-cotent">
        <div class="fontStyle">预览</div>
        <div class="mobile" style="width: 100%">
          <div class="mobile-inner">
            <img
              src="../../../../assets/images/mobile1bg.png"
              alt=""
              class="bg-img"
            />

            <div class="preview-content">
              <div class="title fs-18">
                {{ templateContent.title }}
              </div>
              <a-form>
                <a-form-item
                  v-for="(value, key) in contentMap"
                  :key="key"
                  :label="value == '*' ? '' : value"
                >
                  <span style="color: #9d9d9d; word-break: break-all">{{
                    form[key]
                  }}</span>
                </a-form-item>
              </a-form>
              <div class="prewiew-button">
                <span>详情</span><RightOutlined />
              </div>
            </div>
          </div>
        </div>
      </a-col>
      <!-- 右侧编辑区域 -->
      <a-col :span="14" class="iss-cotent iss-cotent-edit">
        <div class="mb-30 pl-24 fontStyle">
          已选择模版 - {{ templateContent.title }}
        </div>
        <a-form
          ref="formRef"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 20 }"
          :model="form"
        >
          <a-form-item :wrapper-col="{ offset: 2 }">
            <div class="tip-box mb-30">
              <p>
                <InfoCircleOutlined
                  style="color: red"
                  class="mr-10 pt-20"
                /><span>注意！</span>
              </p>
              <p>
                1.请注意不用使用此功能群发营销类模板消息，具体请参考微信公众号<a
                  href="https://developers.weixin.qq.com/doc/offiaccount/Message_Management/Template_Message_Operation_Specifications.html"
                  target="_blank"
                  >模板消息运营规范</a
                >
              </p>
              <p>
                2.模板内容长度不能超过200个字符，且必须有至少10个固定文字或标点
              </p>
              <p>3.中间的主内容中，单个字段内容不超过20个字，且不支持换行</p>
              <p>4.左侧预览仅供参考，实际以微信粉丝收到的消息为准</p>
            </div>
          </a-form-item>
          <a-form-item
            label="消息名称"
            :rules="[
              { required: true, message: '请输入消息名称' },
              { max: 20, message: '消息名称不能超过20个字符' },
            ]"
          >
            <a-input
              v-model:value="form.title"
              placeholder="请输入消息名称"
              :maxLength="20"
            />
          </a-form-item>
          <!-- <a-form-item label="模版消息类型" :rules="[required]">
            <a-radio-group v-model:value="form.templateType">
              <a-radio :value="0">通知类</a-radio>
              <a-radio :value="1">营销类</a-radio>
            </a-radio-group>
          </a-form-item>-->
          <a-form-item
            v-for="(value, key) in contentMap"
            :key="key"
            :label="value == '*' ? '' : value"
            :wrapper-col="{ offset: value == '*' ? 4 : 0 }"
            :rules="[
              { required: true, message: `请输入${value}` },
              { max: 20, message: '输入内容不能超过20个字符' },
            ]"
          >
            <a-input
              v-if="value == '*'"
              v-model:value="form[key]"
              placeholder="请输入"
              class="input-item"
              :maxLength="20"
            ></a-input>
            <a-date-picker
              style="width: 50%"
              v-else-if="key.includes('time')"
              v-model:value="form[key]"
              value-format="yyyy年MM月DD日"
              placeholder="请选择"
            ></a-date-picker>
            <div class="reset-textarea" v-else>
              <div class="textarea-top"></div>
              <a-textarea
                v-model:value="form[key]"
                show-count
                :maxlength="20"
                style="width: 100%"
                :placeholder="`请输入${value}`"
                @keydown.enter.prevent="preventNewline" 
              />
            </div>
          </a-form-item>
          <a-form-item label="详细地址" :rules="[required]">
            <a-input
              v-model:value="form.url"
              placeholder="请输入网址地址"
            />
          </a-form-item>
          <a-form-item
            :wrapper-col="{ span: 20, offset: 4 }"
            :rules="[required]"
          >
            <a-checkbox v-model:checked="form.trackClick"
              >是否跟踪用户点击行为</a-checkbox
            >
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 24 }">
            <div style="width: 100%; display: flex; justify-content: center">
              <a-button @click="handleCancel">取消</a-button>
              <a-button
                type="primary"
                style="margin-left: 70px"
                @click="handleDebounceSave"
                >保存</a-button
              >
            </div>
          </a-form-item>
        </a-form>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, ref } from 'vue';
import {
  Row,
  Col,
  Form,
  // Radio,
  DatePicker,
  Checkbox,
  message,
} from 'ant-design-vue';
import { useRoute, useRouter } from 'vue-router';
import { InfoCircleOutlined, RightOutlined } from '@ant-design/icons-vue';
import wechatApi from '@/api/wechatServer';
import { useStore } from 'vuex';
import { debounce } from '@/utils';
export default {
  components: {
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    // ARadio: Radio,
    // ARadioGroup: Radio.Group,
    ADatePicker: DatePicker,
    ACheckbox: Checkbox,
    InfoCircleOutlined,
    RightOutlined,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const formRef = ref(null);
    const required = { required: true, message: '不能为空' };
    const detailId = route.params.id;

    const templateListState = reactive({
      templateContent: {},
      contentMap: {},
      templateContentDetail: [],
    });
    const form = reactive({
      title: '',
      url: '',
      templateType: 0,
      trackClick: false,
    });
    // 判断是否为空
    const isInputEmpty = value => {
      return value.trim() === '';
    };
    const handleSave = () => {
      let { url, trackClick, title, templateType, ...res } = form;
      let data = {
        wechatTemplateId: templateListState.templateContent.templateId,
        appId: templateListState.templateContent.appId,
        templateName: title,
        url,
        trackClick,
        title: templateListState.templateContent.title,
        templateType,
        templateContentMap: res,
      };
      // 进行校验
      for (let key in form) {
        switch (key) {
          case 'title':
            if (isInputEmpty(form[key])) {
              return message.error('消息名称不能为空');
            }
            break;
          case 'url':
            if (isInputEmpty(form[key])) {
              return message.error('网址地址不能为空');
            }
            break;
        }
        for (let k in templateListState.contentMap) {
          if (key == k && isInputEmpty(form[key])) {
            if (templateListState.contentMap[k] == '*') {
              return message.error('输入不能为空');
            } else {
              return message.error(
                `${templateListState.contentMap[k]}不能为空`
              );
            }
          }
        }
      }

      if (route.params.wechatTemplateId == 'add') {
        wechatApi.addTemplateList({}, data).then(res => {
          if (res) {
            message.success('创建成功');
            router.back();
          }
        });
      } else {
        data.id = route.params.id;
        wechatApi.editTemplateList({}, data).then(res => {
          if (res) {
            message.success('编辑成功');
            router.back();
          }
        });
      }
    };
    // 获取模版内容(新增)
    const getTemplateContentFun = id => {
      wechatApi.getTemplateById({}, id).then(res => {
        if (!res) {
          console.log(res);
        } else {
          templateListState.templateContent = res;
          templateListState.contentMap = res.contentMap;
          for (let key in res.contentMap) {
            form[key] = '';
          }
          console.log('模版内容', templateListState.templateContent);
        }
      });
    };
    //获取模版内容 (编辑)
    const getTemplateContentByWechatFun = id => {
      wechatApi.getTemplateByWechatId({}, { templateId: id }).then(res => {
        if (!res) {
          console.log(res);
        } else {
          templateListState.templateContent = res;
          templateListState.contentMap = res.contentMap;
          console.log(res, 4444);
        }
      });
    };

    const getSendTemplateContentFun = async (id, wechatTemplateId) => {
      await getTemplateContentByWechatFun(wechatTemplateId);
      wechatApi
        .getSendTemplateById({}, { id, appId: store.state.account.appId })
        .then(res => {
          if (!res) {
            console.log(res);
          } else {
            form.title = res.templateName;
            form.templateType = res.templateType;
            form.trackClick = res.trackClick;
            form.url = res.url;
            for (let key in res.templateContentMap) {
              form[key] = res.templateContentMap[key];
            }
            console.log('模版内容', templateListState.templateContent);
          }
        });
    };
    onMounted(() => {
      if (route.params.wechatTemplateId == 'add') {
        getTemplateContentFun(detailId);
      } else {
        getSendTemplateContentFun(detailId, route.params.wechatTemplateId);
      }
    });
    return {
      ...toRefs(templateListState),
      form,
      formRef,
      required,
      preventNewline(){
      },
      handleCancel() {
        router.back();
      },
      handleDebounceSave() {
        debounce(handleSave(), 1000);
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main {
  background: transparent;
  min-height: calc(100vh - 92px);

  padding: 20px;
  align-items: stretch;
  .iss-cotent {
    background-color: #fff;
    height: 100%;
    padding: 24px 32px;
    height: calc(100vh - 82px);
  }
  .iss-cotent-edit {
    padding: 24px 32px 24px 0;
    overflow-y: auto;
  }
}
.tip-box {
  border-radius: 8px;
  background: #fffae3;
  padding: 14px 24px;
}
.mobile {
  height: 74vh;
  margin: 0 auto;
  margin-top: 20px;
  padding: 0 21px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1360px) {
    height: 70vh;
  }
  @media only screen and (max-width: 1260px) {
    height: 68vh;
  }

  .mobile-inner {
    height: 100%;
    position: relative;
  }
  .bg-img {
    height: 100%;
    object-fit: cover;
  }

  .preview-content {
    position: absolute;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    width: 80%;
    max-height: 56vh;
    margin: 0 auto;
    overflow-y: auto;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);

  
    @media only screen and (max-width: 1360px) {
      max-height: 52vh;
    }
    @media only screen and (max-width: 1260px) {
      max-height: 48vh;
    }
   
  }
}
:deep(.ant-radio-inner::after) {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  background-color: #999999;
}
:deep(.ant-radio-checked .ant-radio-inner::after) {
  background-color: #5979f8;
}
:deep(.ant-form-item textarea.ant-input) {
  height: 128px;
  padding-top: 34px;
  white-space: nowrap;
}
.reset-textarea {
  border-radius: 4px;
  position: relative;
  &:hover {
    // .textarea-top{
    //   border-color: #5979f8;
    // }
  }
}
.textarea-top {
  position: absolute;
  width: calc(100% - 2px);
  height: 30px;
  top: 1px;
  left: 1px;
  border-radius: 4px 4px 0px 0px;
  background: #f4f4f4;
  z-index: 2;
  // border: 1px solid #e5e6eb;
  border-bottom: none;
}
.prewiew-button {
  border-top: 1px solid #bbbbbb;
  padding: 10px 0;
}
.fontStyle {
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
</style>
